import React, { useEffect, useState } from 'react';
import arrow from '../../assets/icons/Arrow.svg';
import { baseUrl } from '../../config';
import Mandatory from '../../assets/icons/Mandatory.svg';
import Cross from '../../assets/icons/Cross.svg';
import Loader from '../../assets/icons/Loader.gif';
import { ReactComponent as GreySwipeBar } from '../../assets/icons/GreySwipeBar.svg';
import '../LinkWith/LinkWith.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';

interface ContactShareProps {
  closeShareContact: () => void;
}

const ContactShare = ({ closeShareContact }: ContactShareProps) => {
  const [data, setData] = useState({
    FullName: '',
    FriendsEmail: '',
    FriendsPhone: '',
    ReceiverName: '',
    url: '',
  });
  const [formattedNumber, setFormattedNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const link = window.location.pathname.includes('link');
  const params = useParams();

  const handleShareProfile = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    if (params.cardId) {
      const body = {
        fullName: data.FullName,
        receiverName: data.ReceiverName,
        email: data.FriendsEmail,
        phoneNumber: data.FriendsPhone,
        cardId: params.cardId,
      };
      const token = localStorage.getItem('token');
      console.log({ body }, 'body');
      axios
        .post(`${baseUrl}/card/share/profile-card-web`, body, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setLoading(false);
          closeShareContact();
          toast.success('Form submmitted successfully');
          console.log(res);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.response?.data?.message);
          console.log(err, 'err');
        });
    }
  };

  const handleNumberChange = (text: any) => {
    let formatted = '';
    text = text.target.value;
    text = text.replace(/\D/g, '');
    if (text.length > 0) {
      formatted = '(' + text.slice(0, 3);
    }
    if (text.length > 3) {
      formatted += ') ' + text.slice(3, 6);
    }
    if (text.length > 6) {
      formatted += '-' + text.slice(6, 10);
    }
    setFormattedNumber(formatted);
    setData((s) => ({
      ...s,
      FriendsPhone: text,
    }));
  };

  const handleOnChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setData({ ...data, url: window.location.href });
  }, [params.cardId]);
  return (
    <AnimatePresence>
      <motion.div
        style={{
          height: link ? '100vh' : '100%',
          paddingBottom: link ? '30%' : '',
          opacity: loading ? 0.6 : 1,
        }}
        className='main_div'
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ duration: 0.4, stiffness: 100 }}
      >
        {' '}
        <form onSubmit={handleShareProfile} className='submain_div'>
          {loading ? (
            <div className='loader_div'>
              <div style={{ width: '10%' }}>
                <img src={Loader} alt='' />
              </div>
            </div>
          ) : (
            <>
              <button
                style={{ all: 'unset', marginInline: 'auto', display: 'block' }}
              >
                <GreySwipeBar />
              </button>
              <div className='cross_div'>
                <button onClick={closeShareContact}>
                  <img src={Cross} alt='close' />
                </button>
              </div>
              <div className='linkWith'>
                {/* <span className='linkWith_span'></span> */}
                <h2 className='linkWith_name'>Share Profile Card</h2>
              </div>
              <div className='inputField_div'>
                <p className='label_field'>
                  Your Full Name{' '}
                  <img className='staric' src={Mandatory} alt='mandatory' />
                </p>
                <input
                  onChange={handleOnChange}
                  name='FullName'
                  value={data.FullName}
                  className='inputField'
                  type='text'
                  required
                  placeholder='i.e.: First M. Last'
                />
                <div className='border'></div>
              </div>
              <div className='inputField_div'>
                <p className='label_field'>
                  Friends Name
                  <img className='staric' src={Mandatory} alt='mandatory' />
                </p>
                <input
                  onChange={handleOnChange}
                  name='ReceiverName'
                  value={data.ReceiverName}
                  className='inputField'
                  type='text'
                  required
                  placeholder='i.e.: First M. Last'
                />
                <div className='border'></div>
              </div>
              <div className='inputField_div'>
                <p className='label_field'>
                  Friends Email
                  <img className='staric' src={Mandatory} alt='mandatory' />
                </p>
                <input
                  onChange={handleOnChange}
                  name='FriendsEmail'
                  value={data.FriendsEmail}
                  className='inputField'
                  type='email'
                  required
                  placeholder='i.e.: name@domain.com'
                />
                <div className='border'></div>
              </div>
              <div className='inputField_div'>
                <p className='label_field'>
                  Friends Phone Number{' '}
                  <img className='staric' src={Mandatory} alt='mandatory' />
                </p>
                <input
                  onChange={handleNumberChange}
                  // onWheel={handleWheel}
                  value={formattedNumber}
                  className='inputField'
                  type='tel'
                  maxLength={14}
                  required
                  placeholder='i.e.: (408) 123-4567'
                />
                <div className='border'></div>
              </div>
              <div className='inputField_div'>
                <p className='label_field'>Profile URL</p>
                <input
                  readOnly
                  value={data.url}
                  className='inputField'
                  type='text'
                />
                <div className='border'></div>
              </div>
              <div className='button_div'></div>
              <div className='button_div'>
                <button type='submit' className='plus_btn_div sendContact_btn'>
                  <p className='moreDetails_btn sendContact'>Share</p>
                  <img className='plus_img' src={arrow} alt='camera' />
                </button>
              </div>
            </>
          )}
        </form>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContactShare;
