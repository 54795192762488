import React, { useState } from "react";
import { Drawer, Typography, Button } from "@mui/material";
import closeImgUrl from "../../assets/Back Icon.svg";
import serviceImgUrl from "../../assets/JoeICompany.png";
import Linkicon from "../../assets/linkicon.svg";
import { ReactComponent as GreySwipeBar } from "../../assets/icons/GreySwipeBar.svg";

interface ServiceDetailsDrawerProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  photo: string;
  url: any;
}

const ServiceDetailsDrawer: React.FC<ServiceDetailsDrawerProps> = ({
  open,
  onClose,
  title,
  description,
  photo,
  url,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);

  const handleToggleReadMore = () => {
    setIsExpanded(!isExpanded);
    setIsReadMore(!isReadMore);
  };
  const shouldShowReadMore = description.length >= 100;
  console.log(description.length, "shouldShowReadMore", shouldShowReadMore);
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#181A1C",
          borderRadius: "24px 24px 0px 0px",
          overflow: "scroll",
        },
      }}
    >
      <button
        style={{
          all: "unset",
          marginInline: "auto",
          display: "block",
          width: "47px",
        }}
      >
        <GreySwipeBar />
      </button>
      <div
        style={{
          width: "auto",
          height: "100vh",
          backgroundColor: "#181A1C",
          display: "flex",
          padding: "25px",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div>
          <img
            src={closeImgUrl}
            alt="Close"
            onClick={onClose}
            style={{
              position: "absolute",
              top: 30,
              left: 30,
              width: 33,
              height: 33,
              cursor: "pointer",
            }}
          />
        </div>
        <div
          style={{
            marginTop: "30px",
            width: "auto",
            display: "flex",

            flexDirection: "column",
            gap: "32px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "33px",
              alignItems: "start",
              marginTop: "30px",
            }}
          >
            {" "}
            <Typography
              fontSize={"17px"}
              fontWeight={"700"}
              lineHeight={"10.29px"}
              letterSpacing={"4.5%"}
              color={"#FFFFFF"}
            >
              {title}
            </Typography>
            <div
              style={{
                width: "100%",
                height: "213px",
                borderRadius: "15px",
                backgroundColor: photo ? "#000000" : "#333333", // Adjust background color if no image
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {photo ? (
                <img
                  src={photo}
                  alt="Service"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "15px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Typography fontSize={"16px"} color={"#9B9DA4"}>
                  No Image
                </Typography>
              )}
            </div>
            <Typography
              fontSize={"14px"}
              fontWeight={"700"}
              lineHeight={"8px"}
              letterSpacing={"2.5%"}
            >
              Service Details
            </Typography>
            <div>
              <Typography
                variant="body1"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: isExpanded ? "unset" : "3",
                  WebkitBoxOrient: "vertical",
                  cursor: shouldShowReadMore ? "pointer" : "default",
                  color: "#9B9DA4",
                }}
                onClick={shouldShowReadMore ? handleToggleReadMore : undefined}
              >
                {isExpanded ? description : description.slice(0, 100)}
                {description.length > 100 && !isExpanded ? "..." : ""}{" "}
                {shouldShowReadMore && (
                  <span
                    style={{
                      color: "#9B9DA4",
                      fontSize: "16px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                    onClick={handleToggleReadMore}
                  >
                    {isExpanded ? " (Read Less)" : " (Read More)"}
                  </span>
                )}
              </Typography>
              {/* {shouldShowReadMore && (
                <span
                  style={{
                    color: "#9B9DA4",
                    fontSize: "16px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  onClick={handleToggleReadMore}
                >
                  {isExpanded ? " (Read Less)" : " (Read More)"}
                </span>
              )} */}
            </div>
          </div>

          <hr
            style={{
              width: "100%",
              borderTop: "1px solid #777777",
              margin: "0",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "22px",
              alignItems: "start",
            }}
          >
            <Typography
              fontSize={"14px"}
              fontWeight={"700"}
              lineHeight={"8px"}
              letterSpacing={"2.5%"}
            >
              Links
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img
                src={Linkicon}
                alt="Icon"
                style={{
                  width: 20,
                  height: 20,
                }}
              />
              <a
                href={url}
                style={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {url}
              </a>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={onClose}
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  //   left: "50%",
                  width: "25%",
                  backgroundColor: "#181A1C",
                  color: "#FFFFFF",
                  textTransform: "unset",
                  borderRadius: "50px",
                  border: "1px solid #ffffff",
                  padding: "14px 21px",
                  cursor: "pointer",
                }}
              >
                Exit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ServiceDetailsDrawer;
