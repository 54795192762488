let baseUrl = "https://dev-api.hlyx.me/api/v1";

// Update the baseUrl on every environment
// eslint-disable-next-line default-case
switch (window.location.host) {
  case "dev.hlyx.me":
    baseUrl = "https://dev-api.hlyx.me/api/v1";
    break;

  case "demo.hlyx.me":
    baseUrl = "https://demo-api.hlyx.me/api/v1";
    break;

  case "www.hlyx.me":
    baseUrl = "https://api.hlyx.me/api/v1";
    break;

  case "hlyx.me":
    baseUrl = "https://api.hlyx.me/api/v1";
    break;
}

export { baseUrl };
