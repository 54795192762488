// import Header from '../../components/Profile/Header'
import { useState } from "react";
import hlyxLogo from "../../assets/HlyxLogo.svg";
import location from "../../assets/icons/location.svg";
import privateProfile from "../../assets/Private.svg";
import testPic from "../../assets/TestingDp.svg";
import "./PrivateProfile.css";

const PrivateProfile = ({ card }: any) => {
  const [reqAccess, setReqAccess] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <div className={`${reqAccess && "dim"} bgBlack`}>
        {/* <Header optionsClass="visibility_none" /> */}
        <div className="profile_div">
          <img
            className="profilePic"
            src={card?.companyIcon}
            alt="profileImage"
          />
          <h2 className="name">{card?.fullName}</h2>
          <p className="position">
            {card?.profTitle} at{" "}
            <span className="office_name">{card?.company}</span>
          </p>
          <div className="location_div">
            <img src={location} alt="location" />
            <p className="location_name">
              {card?.state}, {card?.city}
            </p>
          </div>
        </div>
        <div className="border"></div>
        <div className="privateProfile_div">
          <img src={privateProfile} alt="private" />
          <p className="privateProfile">This profile is set to private</p>
        </div>
        <div className="RequestAccessBtn_div">
          <button
            onClick={() => setReqAccess(true)}
            className="RequestAccessBtn"
          >
            Request Access
          </button>
        </div>
        <div className="network_div pd-20">
          <p>Your network empowered by</p>
          <div>
            <img src={hlyxLogo} alt="hlyxLogo" />
          </div>
        </div>
      </div>
      {reqAccess && (
        <div className="createAnAccountModal">
          <h2 className="create">Create An Account</h2>
          <p className="create_p">
            You must have a HLYX account to request access to {card?.fullName}{" "}
            profile
          </p>
          <div className="btns">
            <button
              onClick={() => {
                window.location.href = "https://www.hlyx.io/register";
              }}
              className="createBtn"
            >
              Create Account
            </button>
            <button onClick={() => setReqAccess(false)} className="dismissBtn">
              Dismiss
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivateProfile;
