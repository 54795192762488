import { IonRouterOutlet, IonTabBar, IonTabs } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router";
import Error404 from "../components/Error404";
import ProfileProvider from "../context/profileContext";
import useAuth from "../hooks/useAuth";
import Login from "../pages/Auth/Login";
import Professional from "../pages/Professional";
import Profile from "../pages/Profile";
import Demo from "../pages/Demo";
import DemoMainPage from "../pages/DemoMainPage";

const Routes = () => {
  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          {/* <Route exact path="/">
            <Professional />
          </Route> */}
          <Route exact path="/:id">
            <ProfileProvider>
              <DemoMainPage />
            </ProfileProvider>
          </Route>
          <Route exact path="/pl/:cardId">
            <Professional />
          </Route>
          <Route exact path="/link/:cardId">
            <ProfileProvider>
              <Profile />
            </ProfileProvider>
          </Route>
          <Route exact path="/demo/:id">
            <ProfileProvider>
              <Demo />
            </ProfileProvider>
          </Route>
          <Route exact path="/page-not-found">
            <Error404 />
          </Route>
        </IonRouterOutlet>
        <IonTabBar />
      </IonTabs>
    </IonReactRouter>
  );
};

export default Routes;
