import React, { useContext, useState } from "react";
import "../style/components/featureServices.css";
import { IonButton } from "@ionic/react";
import {
  MessagesIcon,
  SaveIcon,
  ShareFeaturedIcon,
} from "../assets/icons/Featured_Card_Icons";
import { ProfileContext } from "../context/profileContext";
import ServiceDetails from "./ServiceDetails/ServiceDetails";

const FeaturedServices = ({
  title,
  description,
  lineColor,
  photo,
  url,
}: {
  title: string;
  description: string;
  lineColor: string;
  photo: string;
  url: any;
}) => {
  const { userProfile } = useContext(ProfileContext);
  console.log({ userProfile });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="featured_card_container">
      <div className="content_container">
        <div className={`color_line border-${lineColor}`} />
        <div className="featured_details_container">
          <div className="featured_details">
            <h6>{title}</h6>
            <p className="truncated-description">{description}</p>
          </div>
        </div>
      </div>
      <div className="card_footer">
        <IonButton
          shape="round"
          size="small"
          className="footer_button"
          onClick={handleClickOpen}
        >
          Details
        </IonButton>
        <div className="featured_icons">
          <MessagesIcon />
          <SaveIcon />
          <ShareFeaturedIcon />
        </div>
      </div>
      <ServiceDetails
        open={open}
        onClose={handleClose}
        title={title}
        description={description}
        photo={photo}
        url={url}
      />
    </div>
  );
};

export default FeaturedServices;
