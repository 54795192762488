import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import Plus from '../../assets/icons/Plus.svg';
import Camera from '../../assets/icons/CameraIcon.svg';
import arrow from '../../assets/icons/Arrow.svg';
import editIcon from '../../assets/icons/EditIcon.svg';
import Mandatory from '../../assets/icons/Mandatory.svg';
import PencilIcon from '../../assets/icons/PencilIcon.svg';
import whiteCamera from '../../assets/whiteCamera.svg';
import Cross from '../../assets/icons/Cross.svg';
import Loader from '../../assets/icons/Loader.gif';
import { ReactComponent as GreySwipeBar } from '../../assets/icons/GreySwipeBar.svg';
import './LinkWith.css';
import Cropper from 'react-easy-crop';
import axios from 'axios';
import { baseUrl } from '../../config';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';

interface LinkWithProps {
  closeLinkWith: () => void;
  name: string;
}

const LinkWith = ({ closeLinkWith, name }: LinkWithProps) => {
  // const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [croppedImage, setCroppedImage] = useState('');
  // const [zoom, setZoom] = useState(1);
  const [data, setData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
    company: '',
    jobTitle: '',
    city: '',
    state: '',
    notes: '',
  });
  const [formattedNumber, setFormattedNumber] = useState('');
  const [moreDetails, setMoreDetails] = useState(true);
  const [source, setSource] = useState('');
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const link = window.location.pathname.includes('link');
  const { cardId } = params;
  const body = new FormData();

  body.append('contactImage', image);
  if (cardId) {
    body.append('cardId', cardId);
  }

  Object.entries(data).forEach(
    ([key, value]) => value && body.append(key, value)
  );

  const handleSendContact = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem('token');
    axios
      .post(`${baseUrl}/contact/create`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setLoading(false);
        closeLinkWith();
        toast.success('Form submmitted successfully');
        setData({
          fullName: '',
          email: '',
          phone: '',
          message: '',
          company: '',
          jobTitle: '',
          city: '',
          state: '',
          notes: '',
        });
        setImage('');
        setSource('');
        // setCroppedImage('');
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
        console.log(err, 'err');
      });
  };

  const handleNumberChange = (text: any) => {
    let formatted = '';
    text = text.target.value;
    text = text.replace(/\D/g, '');
    if (text.length > 0) {
      formatted = '(' + text.slice(0, 3);
    }
    if (text.length > 3) {
      formatted += ') ' + text.slice(3, 6);
    }
    if (text.length > 6) {
      formatted += '-' + text.slice(6, 10);
    }
    setFormattedNumber(formatted);
    setData((s) => ({
      ...s,
      phone: text,
    }));
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const source = reader.result as string;
        setSource(source || '');
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOnChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleMoreDetails = () => {
    setMoreDetails(!moreDetails);
  };
  const handleEditButton = () => {
    setEdit(!edit);
  };
  const handleSelfiePhoto = () => {
    const fileInput = document.getElementById('icon-button-file');
    if (fileInput) {
      fileInput.click();
    }
    setEdit(false);
  };
  const handleRemove = () => {
    setSource('');
    setImage('');
    setEdit(false);
  };

  // const handleCropChange = useCallback((newCrop: any) => {
  //   setCrop(newCrop);
  // }, []);

  // const handleCropComplete = useCallback(
  //   (croppedArea: any, croppedAreaPixels: any) => {
  //     if (source) {
  //       const image = new Image();
  //       image.src = source;

  //       image.onload = () => {
  //         const canvas = document.createElement('canvas');
  //         const ctx = canvas.getContext('2d');

  //         if (!ctx) {
  //           // Handle the case where canvas context cannot be obtained
  //           console.error('Failed to obtain canvas context');
  //           return;
  //         }
  //         canvas.width = croppedAreaPixels.width;
  //         canvas.height = croppedAreaPixels.height;

  //         ctx.drawImage(
  //           image,
  //           croppedAreaPixels.x,
  //           croppedAreaPixels.y,
  //           croppedAreaPixels.width,
  //           croppedAreaPixels.height,
  //           0,
  //           0,
  //           croppedAreaPixels.width,
  //           croppedAreaPixels.height
  //         );

  //         const croppedImageDataURL = canvas.toDataURL('image/jpeg');
  //         setCroppedImage(croppedImageDataURL);
  //       };
  //     }
  //   },
  //   [source]
  // );
  // const handleSave = () => {
  //   setSource('');
  // };
  // const handleWheel = (e: any) => {
  //   e.target.blur();
  // };
  return (
    <AnimatePresence>
      <motion.div
        style={{
          height: link ? '100vh' : '100%',
          paddingBottom: link ? '30%' : '',
          opacity: loading ? 0.6 : 1,
        }}
        className='main_div'
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ duration: 0.4, stiffness: 100 }}
      >
        {' '}
        <form onSubmit={handleSendContact} className='submain_div'>
          {loading ? (
            <div className='loader_div'>
              <div style={{ width: '10%' }}>
                <img src={Loader} alt='' />
              </div>
            </div>
          ) : (
            <>
              <button
                style={{ all: 'unset', marginInline: 'auto', display: 'block' }}
              >
                <GreySwipeBar />
              </button>
              <div className='cross_div'>
                <button onClick={closeLinkWith}>
                  <img src={Cross} alt='close' />
                </button>
              </div>
              <div className='linkWith'>
                <span className='linkWith_span'>link with</span>
                <h2 className='linkWith_name'>{name}</h2>
              </div>
              <div className='editCam_div'>
                <div className='blackBG'>
                  <img
                    className={source && 'Dp'}
                    src={source ? source : whiteCamera}
                    alt='edit'
                  />
                  <img
                    onClick={handleEditButton}
                    className='pencilIcon'
                    src={PencilIcon}
                    alt='edit'
                  />
                  {edit && source && (
                    <div className='options_div'>
                      <p className='options_p'>Photo options</p>
                      <div className='border_grey'></div>
                      <div className='options_retake'>
                        <button
                          onClick={handleSelfiePhoto}
                          className='options_p'
                        >
                          Retake
                        </button>
                        <div className='border_grey'></div>
                        <button onClick={handleRemove} className='options_p'>
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                {/* {source && (
      <Cropper
        image={source}
        crop={crop}
        aspect={1}
        onCropChange={handleCropChange}
        onCropComplete={handleCropComplete}
        zoom={zoom}
        onZoomChange={setZoom}
      />
    )} */}
                <div className='inputField_div'>
                  <p className='label_field'>
                    Full Name{' '}
                    <img className='staric' src={Mandatory} alt='mandatory' />
                  </p>
                  <input
                    onChange={handleOnChange}
                    name='fullName'
                    value={data.fullName}
                    className='inputField'
                    type='text'
                    required
                    placeholder='i.e.: First M. Last'
                  />
                  <div className='border'></div>
                </div>
                <div className='inputField_div'>
                  <p className='label_field'>
                    Email Address{' '}
                    <img className='staric' src={Mandatory} alt='mandatory' />
                  </p>
                  <input
                    onChange={handleOnChange}
                    name='email'
                    value={data.email}
                    className='inputField'
                    type='email'
                    required
                    placeholder='i.e.: name@domain.com'
                  />
                  <div className='border'></div>
                </div>
                <div className='inputField_div'>
                  <p className='label_field'>
                    Phone Number{' '}
                    <img className='staric' src={Mandatory} alt='mandatory' />
                  </p>
                  <input
                    onChange={handleNumberChange}
                    // onWheel={handleWheel}
                    name='phone'
                    value={formattedNumber}
                    className='inputField'
                    type='tel'
                    maxLength={14}
                    required
                    placeholder='i.e.: (408) 123-4567'
                  />
                  <div className='border'></div>
                </div>
              </div>
              {moreDetails && (
                <div className='inputField_div'>
                  <p className='label_field'>Message</p>
                  <textarea
                    onChange={handleOnChange}
                    name='message'
                    value={data.message}
                    className='inputField'
                    placeholder='Enter a brief and simple message. '
                  />
                  <div className='border'></div>
                </div>
              )}
              {!moreDetails && (
                <div>
                  <div className='inputField_div'>
                    <p className='label_field'>Business Name</p>
                    <input
                      onChange={handleOnChange}
                      name='company'
                      value={data.company}
                      className='inputField'
                      type='text'
                      placeholder='i.e.: ABC Business Co.'
                    />
                    <div className='border'></div>
                  </div>
                  <div className='inputField_div'>
                    <p className='label_field'>Job Title</p>
                    <input
                      onChange={handleOnChange}
                      name='jobTitle'
                      value={data.jobTitle}
                      className='inputField'
                      type='text'
                      placeholder='i.e.: Founder and CFO'
                    />
                    <div className='border'></div>
                  </div>
                  <div className='location_state_div inputField_div'>
                    <div style={{ flexBasis: '54%' }}>
                      <p className='label_field'>Location</p>
                      <input
                        onChange={handleOnChange}
                        name='city'
                        value={data.city}
                        className='inputField'
                        type='text'
                        placeholder='i.e.: San Diego'
                      />
                      <div className='border mb-5'></div>
                      <span className='city'>City</span>
                    </div>
                    <div style={{ flexBasis: '35%' }}>
                      <input
                        onChange={handleOnChange}
                        name='state'
                        value={data.state}
                        className='inputField'
                        type='text'
                        placeholder='i.e.: CA'
                      />
                      <div className='border mb-5'></div>
                      <span className='city'>State</span>
                    </div>
                  </div>
                  <div className='inputField_div'>
                    <p className='label_field'>Message</p>
                    <div className='relative'>
                      <textarea
                        onChange={handleOnChange}
                        name='message'
                        value={data.message}
                        className='inputField'
                        placeholder='Enter a brief and simple message. '
                      />
                      {data.message === '' && (
                        <img className='editIcon' src={editIcon} alt='edit' />
                      )}
                    </div>
                    <div className='border'></div>
                  </div>
                  <div className='inputField_div'>
                    <p className='label_field'>Notes</p>
                    <textarea
                      onChange={handleOnChange}
                      name='notes'
                      value={data.notes}
                      className='inputField'
                      placeholder='Add a custom note to help you remember.'
                    />
                    <div className='border'></div>
                  </div>
                </div>
              )}
              <div className='button_div'>
                <button
                  onClick={handleMoreDetails}
                  type='button'
                  className='plus_btn_div'
                >
                  <img className='plus_img' src={Plus} alt='plus' />
                  <p className='moreDetails_btn'>More Details</p>
                </button>
                <div>
                  <input
                    type='file'
                    accept='image/*'
                    capture='user'
                    onChange={handleFileChange}
                    id='icon-button-file'
                    style={{ display: 'none' }}
                  />

                  <button
                    onClick={handleSelfiePhoto}
                    type='button'
                    className='plus_btn_div blue_bg'
                  >
                    <img className='plus_img' src={Camera} alt='camera' />
                    <p className='moreDetails_btn blue_bg'>Selfie Photo</p>
                  </button>
                </div>
              </div>
              <div className='button_div'>
                <button type='submit' className='plus_btn_div sendContact_btn'>
                  <p className='moreDetails_btn sendContact'>Send Contact</p>
                  <img className='plus_img' src={arrow} alt='camera' />
                </button>
              </div>
            </>
          )}
        </form>
      </motion.div>
    </AnimatePresence>
  );
};

export default LinkWith;
