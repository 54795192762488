import { useState, useContext, useEffect, useMemo } from 'react';
import { IonButton, IonCard, IonContent } from '@ionic/react';
import { ProfileCardProps } from '../../interface';
import { BagIcon, LocationIcon, SwipeBar } from '../../assets/icons';

import '../../style/components/profileCard.css';
import { ProfileContext } from '../../context/profileContext';
import axios from 'axios';
import { baseUrl } from '../../config';
import LinkWith from '../LinkWith/LinkWith';

const ProfileCard = ({
  backgroundImage,
  companyName,
  companyLogo,
  name,
  id,
  position,
  location,
  link,
  blend,
  brandedCoverPhoto,
}: ProfileCardProps) => {
  // const { userProfile } = useContext(ProfileContext);
  const [addLink, setAddLink] = useState<boolean>(false);
  const closeLinkWith = () => {
    setAddLink(false);
  };
  return (
    <>
      {addLink && <LinkWith closeLinkWith={closeLinkWith} name={name} />}

      {id && (
        <IonContent class={`${addLink && 'dim'} overflow-hidden`} fullscreen>
          <img
            style={{
              position: 'absolute',
              inset: 0,
              zIndex: -3,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              background: '#000',
            }}
            src={brandedCoverPhoto}
            alt='user profile'
          />

          <section className={`linear-bg`}></section>

          <article className='cardContainer'>
            {id && (
              <section
                className='logoName'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  paddingInline: 24,
                  paddingBottom: 30,
                }}
              >
                {/* {companyLogo ? (
                <div className="joi-company-logo-section">
                  <img
                    src={companyLogo}
                    alt="company logo"
                    className="joi-company-logo"
                  />
                </div>
              ) : ( */}
                {
                  <div className='company-logo-section'>
                    <img
                      src={companyLogo}
                      alt='company logo'
                      className='company-logo'
                    />
                  </div>
                }
                {/* )} */}

                <h1>{name}</h1>
                <section className='detail-container'>
                  <section className='detail'>
                    <BagIcon />
                    {position && (
                      <p className='position-text'>
                        {position} at{' '}
                        <span style={{ fontWeight: '700', color: 'white' }}>
                          {companyName}
                        </span>
                      </p>
                    )}
                  </section>
                  <section className='detail'>
                    <LocationIcon />
                    {location && <p className='location-text'>{location}</p>}
                  </section>
                </section>
              </section>
            )}
          </article>
          <section className='link-Button-section'>
            {/* <button
              style={{ all: 'unset', marginInline: 'auto', display: 'block' }}
            // onClick={() => {
            //   setbtn(!btn);
            // }}
            >
              <SwipeBar />
            </button> */}
            <IonCard className='button-section'>
              {/* {btn ? ( */}
              <div style={{ display: 'flex', gap: '10px' }}>
                <IonButton
                  expand='block'
                  className='profile-footer-button'
                  // routerLink={`link/${id}`}
                  onClick={() => setAddLink(true)}
                >
                  Add Link
                </IonButton>
                <IonButton
                  color='#0000'
                  expand='block'
                  className='profile-footer-button white_profile'
                  routerLink={`/link/${id}`}
                >
                  View Profile
                </IonButton>
              </div>
              {/* ) : ( */}
              {/* <section className="buttons">
              <IonButton
                color="primary"
                shape="round"
                size="large"
                className="inner-btn"
              >
                Add Link
              </IonButton>
              <IonButton
                color="#0000"
                expand="block"
                className="profile-footer-button white_profile"
              >
                Message
              </IonButton>
            </section> */}
              {/* )} */}
            </IonCard>
          </section>
        </IonContent>
      )}
    </>
  );
};

export default ProfileCard;
