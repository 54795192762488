import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react';
import { bookmark, chatbubble, helpCircle, send } from 'ionicons/icons';
import { LinkIcon } from '../../assets/icons/index';
import './fabbtn.css';

const InfoButton = ({ openLinkWith, isButtonVisible }: any) => {
  return (
    <>
      <IonFab className={`info-button ${isButtonVisible ? 'visible' : ''}`}>
        <IonFabButton>
          <LinkIcon color='#fff' />
        </IonFabButton>
        <IonFabList side='top'>
          <IonFabButton>
            <IonIcon className='help icon-colour' icon={helpCircle}></IonIcon>
          </IonFabButton>
          <IonFabButton onClick={openLinkWith}>
            <IonIcon className='icon-colour' icon={chatbubble}></IonIcon>
          </IonFabButton>
        </IonFabList>
      </IonFab>
    </>
  );
};

export default InfoButton;
