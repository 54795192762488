import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";
import { baseUrl } from "../../config";
import "../../style/page/professional.css";
import axios from "axios";

interface ICardData {
  activeCard: boolean;
  cardName: string;
  city: string;
  company: string;
  coverPhoto?: string;
  fullName: string;
  profTitle: string;
  publicCard: boolean;
  slug?: string;
  state?: string;
  templateId?: string;
  userAvatar: string;
  companyIcon: string;
  brandedCoverPhoto?: string;
}

const Professional = () => {
  const { cardId } = useParams();
  const [cardData, setCardData] = useState<ICardData>();
  const history = useHistory();

  useEffect(() => {
    if (cardId) {
      axios
        .get(`${baseUrl}/card/find/base-info/${cardId}`)
        .then((res) => {
          console.log("resofCard", res);
          // localStorage.setItem('cardType', res.data.card.profileLayout);
          setCardData(res.data.card);
          if (!res?.data?.card?.brandedCoverPhoto) {
            history.push(`/link/${cardId}`);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            window.location.pathname = "/page-not-found";
            // history.push('/page-not-found');
          }
          console.log(err, "RRRRR");
        });
    }
  }, []);

  return (
    <IonPage>
      <IonContent class="overflow-hidden">
        <ProfileCard
          blend={cardData?.fullName}
          id={cardId}
          companyName={cardData?.company ? cardData?.company : ""}
          backgroundImage={cardData?.coverPhoto ? cardData?.coverPhoto : ""}
          brandedCoverPhoto={
            cardData?.brandedCoverPhoto ? cardData?.brandedCoverPhoto : ""
          }
          name={cardData?.fullName ? cardData?.fullName : ""}
          position={cardData?.profTitle ? cardData?.profTitle : ""}
          location={`${cardData?.city ? cardData?.city : ""}, ${
            cardData?.state ? cardData?.state : ""
          }`}
          companyLogo={cardData?.companyIcon ? cardData?.companyIcon : ""}
          link={cardData?.cardName ? cardData?.cardName : ""}
        />
      </IonContent>
    </IonPage>
  );
};

export default Professional;
