import {
  MediumIcon,
  NumFourIcon,
  NumOneIcon,
  NumThreeIcon,
  NumTwoIcon,
  SoundCloudIcon,
} from "../assets/icons";
import { ReactComponent as Audio } from "../assets/icons/audio.svg";
import { ReactComponent as Video } from "../assets/icons/video.svg";
import { ReactComponent as File } from "../assets/icons/file.svg";
import { ReactComponent as Web } from "../assets/icons/web.svg";
import { DriveIcon } from "../assets/icons/index";
import {
  BiDashboardIcon,
  DataMarketIcon,
  DevelopmentIcon,
  IndustrialIcon,
  MultiUnitIcon,
  NNNInvestmentIcon,
  RedovelopmentIcon,
  UiUxIcon,
} from "../assets/icons/ServiceTabIcons";
import {
  BehanceIcon,
  Facebook,
  InstagramIcon,
  LinkedinIcon,
  MaskIcon,
  MediumSocialIcon,
  ShipFaceIcon,
  SkypeIcon,
  SlackIcon,
  Twitter,
  YoutubeIcon,
} from "../assets/icons/SocialIcons";
import SliderOneImage from "../assets/media/slide-one-image.png";
import SliderThreeImage from "../assets/media/slide-three-image.png";
import SliderTwoImage from "../assets/media/slide-two-image.png";
import { ReactComponent as LinkedIn } from "../assets/socialMedia/Linkedin.svg";
import { ReactComponent as Custom } from "../assets/socialMedia/Custom.svg";
import { ReactComponent as Twiter } from "../assets/socialMedia/Twitter.svg";
import { ReactComponent as Slack } from "../assets/socialMedia/Slack.svg";
import { ReactComponent as FiveHunderedPx } from "../assets/socialMedia/500 Px.svg";
import { ReactComponent as AppleMusic } from "../assets/socialMedia/Apple Music.svg";
import { ReactComponent as ApplePodcast } from "../assets/socialMedia/Apple Podcast.svg";
import { ReactComponent as Badoo } from "../assets/socialMedia/Badoo.svg";
import { ReactComponent as Baidu } from "../assets/socialMedia/Baidu.svg";
import { ReactComponent as Behance } from "../assets/socialMedia/Behance.svg";
import { ReactComponent as Clubhouse } from "../assets/socialMedia/Clubhouse.svg";
import { ReactComponent as Dailymotion } from "../assets/socialMedia/Dailymotion.svg";
import { ReactComponent as DevianArt } from "../assets/socialMedia/DevianArt.svg";
import { ReactComponent as Discord } from "../assets/socialMedia/Discord.svg";
import { ReactComponent as Dribble } from "../assets/socialMedia/Dribble.svg";
import { ReactComponent as FaceBook } from "../assets/socialMedia/Facebook.svg";
import { ReactComponent as Flickr } from "../assets/socialMedia/Flickr.svg";
import { ReactComponent as FourSquare } from "../assets/socialMedia/Foursquare.svg";
import { ReactComponent as Giphy } from "../assets/socialMedia/Giphy.svg";
import { ReactComponent as GitHub } from "../assets/socialMedia/GitHub.svg";
import { ReactComponent as Gmail } from "../assets/socialMedia/Gmail.svg";
import { ReactComponent as GoogleMeets } from "../assets/socialMedia/Google Meets.svg";
import { ReactComponent as GooglePodcast } from "../assets/socialMedia/Google Podcast.svg";
import { ReactComponent as Imo } from "../assets/socialMedia/Imo.svg";
import { ReactComponent as Instagram } from "../assets/socialMedia/Instagram.svg";
import { ReactComponent as Ivoox } from "../assets/socialMedia/Ivoox.svg";
import { ReactComponent as Kompoz } from "../assets/socialMedia/Kompoz.svg";
import { ReactComponent as Kwai } from "../assets/socialMedia/Kwai.svg";
import { ReactComponent as Likee } from "../assets/socialMedia/Likee.svg";
import { ReactComponent as Line } from "../assets/socialMedia/Line.svg";
import { ReactComponent as Match } from "../assets/socialMedia/Match.svg";
import { ReactComponent as Medium } from "../assets/socialMedia/Medium.svg";
import { ReactComponent as MeetMe } from "../assets/socialMedia/MeetMe.svg";
import { ReactComponent as Meetup } from "../assets/socialMedia/Meetup.svg";
import { ReactComponent as Messenger } from "../assets/socialMedia/Messenger.svg";
import { ReactComponent as MySpace } from "../assets/socialMedia/MySpace.svg";
import { ReactComponent as Patreon } from "../assets/socialMedia/Patreon.svg";
import { ReactComponent as Pinterest } from "../assets/socialMedia/Pinterest.svg";
import { ReactComponent as QQ } from "../assets/socialMedia/QQ.svg";
import { ReactComponent as Quora } from "../assets/socialMedia/Quora.svg";
import { ReactComponent as Qzone } from "../assets/socialMedia/Qzone.svg";
import { ReactComponent as Reddit } from "../assets/socialMedia/Reddit.svg";
import { ReactComponent as Skype } from "../assets/socialMedia/Skype.svg";
import { ReactComponent as Snapchat } from "../assets/socialMedia/Snapchat.svg";
import { ReactComponent as Soundcloud } from "../assets/socialMedia/Soundcloud.svg";
import { ReactComponent as Spotify } from "../assets/socialMedia/Spotify.svg";
import { ReactComponent as StackOverFlow } from "../assets/socialMedia/Stack Overflow.svg";
import { ReactComponent as Teams } from "../assets/socialMedia/Teams.svg";
import { ReactComponent as Telegram } from "../assets/socialMedia/Telegram.svg";
import { ReactComponent as TikTok } from "../assets/socialMedia/Tik Tok.svg";
import { ReactComponent as Tinder } from "../assets/socialMedia/Tinder.svg";
import { ReactComponent as TruthSocial } from "../assets/socialMedia/Truth Social.svg";
import { ReactComponent as Tumbler } from "../assets/socialMedia/Tumbler.svg";
import { ReactComponent as Twitch } from "../assets/socialMedia/Twitch.svg";
import { ReactComponent as Viber } from "../assets/socialMedia/Viber.svg";
import { ReactComponent as Vimeo } from "../assets/socialMedia/Vimeo.svg";
import { ReactComponent as Vkontakte } from "../assets/socialMedia/Vkontakte.svg";
import { ReactComponent as Watppad } from "../assets/socialMedia/Watppad.svg";
import { ReactComponent as Weechat } from "../assets/socialMedia/Weechat.svg";
import { ReactComponent as Weibo } from "../assets/socialMedia/Weibo.svg";
import { ReactComponent as Whatsapp } from "../assets/socialMedia/Whatsapp.svg";
import { ReactComponent as Xing } from "../assets/socialMedia/Xing.svg";
import { ReactComponent as Yelp } from "../assets/socialMedia/Yelp.svg";
import { ReactComponent as Youtube } from "../assets/socialMedia/Youtube.svg";
import { ReactComponent as Zoom } from "../assets/socialMedia/Zoom.svg";

export const JamesprofessionData = [
  {
    lineColor: "blue",
    title: "Company",
    description: "Oriyn LLC",
  },
  {
    lineColor: "green",
    title: "Title / Role",
    description: "Chief Executive Officer",
  },
  {
    lineColor: "purple",
    title: "Occupation / Profession",
    description: "Entrepreneur / Founder",
  },
  {
    lineColor: "orange",
    title: "Industry",
    description: "Information Technology",
  },
];

export const educationData = [
  {
    description: `College at <span style="font-weight:600">Stanford University</span>`,
  },
  { description: "Masters Degree in Business Marketing" },
  { description: "Graduated Class of 2000" },
];

export const accolatesData = [
  {
    description: "Board Chair at",
    institute: " Boston Medical",
    date: "November 1,2022",
  },
  {
    description: "Deans List 4 Years in a row at",
    institute: " Stanford University",
    date: "August 2017 - May 2022",
  },
  {
    description: "Summa Cum Laude, Honors Club from",
    date: "August 2017 - May 2022 ",
    institute: " Stanford University",
  },
];

export const acheivementData = [
  {
    year: "2023",
    description: (
      <>
        People Magazine{" "}
        <span style={{ fontWeight: 600, color: "var(--text-color)" }}>
          {" "}
          Scientist of The Year
        </span>
      </>
    ),
  },
  {
    year: "2022",
    description: (
      <>
        Harvard Medical Journal{" "}
        <span style={{ fontWeight: 600, color: "var(--text-color)" }}>
          Prestigious Honor Recipient
        </span>{" "}
      </>
    ),
  },
  {
    year: "2021",
    description: (
      <>
        <span style={{ fontWeight: 600, color: "var(--text-color)" }}>
          Presidential Honorin
        </span>{" "}
        Genetic Science
      </>
    ),
  },
];

export const goalsData = [
  {
    icon: <NumOneIcon />,
    description: "Raise $200M in funding for R&D",
  },
  {
    icon: <NumTwoIcon />,
    description:
      "Discover a working nano-molecular bio-therapy to fight cellular necrosy",
  },
  {
    icon: <NumThreeIcon />,
    description:
      "Attract top scientific talent in the fields of Biology and Nano-technology",
  },
  {
    icon: <NumFourIcon />,
    description: `Be listed on <span style="font-weight:600">Forbes Magazines<br /> ‘40 under 40’</span> in the next 2 years`,
  },
];

export const needsData = [
  {
    date: "January 28, 2022",
    heading: "New Company Website",
    status: "Active",
    description:
      "Looking for someone that can create a new company website for us",
  },
  {
    date: "December 11, 2022",
    heading: "Find Business Partner",
    status: "Active",
    description:
      "Looking for business partner that has experience building bio-tech companies",
  },
  {
    date: "November 8, 2022",
    heading: "Seeking Experienced Scientist",
    status: "Filled",
    description:
      "Looking for someone that can create a new company website for us",
  },
];

export const loremText =
  "Biology is my first love. As a Scientist my principal mission in life is the pursuit of life-changing cures. I founded Sphere Bio with the goal of discovering science that Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.";

export const JamesText =
  "I build digital products and ventures that help entrepreneurs and brands succeed. Through data-driven insights and custom software, my team and I specialize in crafting solutions that help companies solve real challenges and achieve game-changing results.";

export const achieveData = [
  {
    achieveGoal: "Create DNA Nano Map",
    description: "Create DNA nano-map sequencing",
    date: "Nov. 1, 2022",
  },
  {
    achieveGoal: "Compete AMT Certification",
    description: "Get AMT Certification by end of year",
    date: "Oct. 8, 2022",
  },
  {
    achieveGoal: "Acquire New Business Office",
    description: "Close on new office location for 2022",
    date: "Sep. 1, 2022",
  },
];

export const brakerData = [
  {
    description: "Hobbies",
    tags: ["Martial Arts", "Stargazing", "Art"],
  },
  {
    description: "Interest",
    tags: ["Reading", "Legos", "Music"],
  },
  {
    description: "Skills",
    tags: ["Painting", "Piano", "Gardening"],
  },
];

export const sliderData = [
  {
    img: SliderOneImage,
    imgAlt: "cel DNA Mapping",
    title: "Cell DNA Mapping",
    subtitle: "Nano-tech AI",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    subtitle: "Discovery Process",
  },
  {
    img: SliderThreeImage,
    imgAlt: "DNA Therapy",
    title: "DNA Therapy",
    subtitle: "Cell Rejuvenation",
  },
  {
    img: SliderOneImage,
    imgAlt: "cel DNA Mapping",
    title: "Cell DNA Mapping",
    subtitle: "Nano-tech AI",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    subtitle: "Discovery Process",
  },
  {
    img: SliderThreeImage,
    imgAlt: "DNA Therapy",
    title: "DNA Therapy",
    subtitle: "Cell Rejuvenation",
  },
];

export const galleryData = [
  {
    img: SliderOneImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderThreeImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderOneImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderTwoImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
  {
    img: SliderThreeImage,
    imgAlt: "cell-interpolization",
    title: "Cell Interpolization",
    publishDate: "Dec. 21, 2022",
  },
];

export const SocialData = [
  {
    link: "https://www.youtube.com/",
    icon: <YoutubeIcon color={"var(--social-back)"} />,
    label: "YouTube",
  },
  {
    link: "https://www.youtube.com/",
    icon: <SlackIcon color={"var(--social-back)"} />,
    label: "Slack",
  },
  {
    link: "https://www.youtube.com/",
    icon: <ShipFaceIcon color={"var(--social-back)"} />,
    label: "Reddit",
  },
  {
    link: "https://www.youtube.com/",
    icon: <MaskIcon color={"var(--social-back)"} />,
    label: "Discord",
  },
  {
    link: "https://www.youtube.com/",
    icon: <Twitter color={"var(--social-back)"} />,
    label: "Twitter",
  },
  {
    link: "https://www.youtube.com/",
    icon: <Facebook color={"var(--social-back)"} />,
    label: "Facebook",
  },
  {
    link: "https://www.youtube.com/",
    icon: <BehanceIcon color={"var(--social-back)"} />,
    label: "Behance",
  },
  {
    link: "https://www.youtube.com/",
    icon: <SkypeIcon color={"var(--social-back)"} />,
    label: "Skype",
  },
  {
    link: "https://www.youtube.com/",
    icon: <InstagramIcon color={"var(--social-back)"} />,
    label: "Instagram",
  },
  {
    link: "https://www.youtube.com/",
    icon: <MediumSocialIcon color={"var(--social-back)"} />,
    label: "Medium",
  },
];

export const SocialMediaIcons: any = {
  linkedin: (
    <LinkedIn color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),
  youtube: (
    <Youtube color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),
  slack: (
    <Slack color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),
  reddit: (
    <Reddit color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  custom: (
    <Custom color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  discord: (
    <Discord color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  twitter: (
    <Twiter color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  facebook: (
    <FaceBook color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  behance: (
    <Behance color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  skype: (
    <Skype color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  instagram: (
    <Instagram color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  fivehundredpixel: (
    <FiveHunderedPx
      color={"var(--social-back)"}
      width="59.6142"
      height="59.6142"
    />
  ),

  "apple music": (
    <AppleMusic color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  "apple podcast": (
    <ApplePodcast
      color={"var(--social-back)"}
      width="59.6142"
      height="59.6142"
    />
  ),

  badoo: (
    <Badoo color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  baidu: (
    <Baidu color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  clubhouse: (
    <Clubhouse color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  dailymotion: (
    <Dailymotion
      color={"var(--social-back)"}
      width="59.6142"
      height="59.6142"
    />
  ),

  devianart: (
    <DevianArt color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  dribble: (
    <Dribble color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  flickr: (
    <Flickr color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  foursquare: (
    <FourSquare color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  giphy: (
    <Giphy color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  github: (
    <GitHub color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  gmail: (
    <Gmail color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  "google meets": (
    <GoogleMeets
      color={"var(--social-back)"}
      width="59.6142"
      height="59.6142"
    />
  ),

  "google podcast": (
    <GooglePodcast
      color={"var(--social-back)"}
      width="59.6142"
      height="59.6142"
    />
  ),

  imo: <Imo color={"var(--social-back)"} width="59.6142" height="59.6142" />,

  ivoox: (
    <Ivoox color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  kompoz: (
    <Kompoz color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  kwai: <Kwai color={"var(--social-back)"} width="59.6142" height="59.6142" />,

  likee: (
    <Likee color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  line: <Line color={"var(--social-back)"} width="59.6142" height="59.6142" />,

  match: (
    <Match color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  medium: (
    <Medium color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  meetme: (
    <MeetMe color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  meetup: (
    <Meetup color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  messenger: (
    <Messenger color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  myspace: (
    <MySpace color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  patreon: (
    <Patreon color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  pinterest: (
    <Pinterest color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  qq: <QQ color={"var(--social-back)"} width="59.6142" height="59.6142" />,

  quora: (
    <Quora color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  qzone: (
    <Qzone color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  snapchat: (
    <Snapchat color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  soundcloud: (
    <Soundcloud color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  spotify: (
    <Spotify color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  "stack overflow": (
    <StackOverFlow
      color={"var(--social-back)"}
      width="59.6142"
      height="59.6142"
    />
  ),

  teams: (
    <Teams color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  telegram: (
    <Telegram color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  "tik tok": (
    <TikTok color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  tinder: (
    <Tinder color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  "truth social": (
    <TruthSocial
      color={"var(--social-back)"}
      width="59.6142"
      height="59.6142"
    />
  ),

  tumbler: (
    <Tumbler color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  twitch: (
    <Twitch color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  viber: (
    <Viber color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  vimeo: (
    <Vimeo color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  vkontakte: (
    <Vkontakte color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  watppad: (
    <Watppad color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  weechat: (
    <Weechat color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  weibo: (
    <Weibo color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  whatsapp: (
    <Whatsapp color={"var(--social-back)"} width="59.6142" height="59.6142" />
  ),

  xing: <Xing color={"var(--social-back)"} width="59.6142" height="59.6142" />,

  yelp: <Yelp color={"var(--social-back)"} width="59.6142" height="59.6142" />,

  zoom: <Zoom color={"var(--social-back)"} width="59.6142" height="59.6142" />,
};

export const ServicesIcons: any = {
  BiDashboard: <BiDashboardIcon />,
  UiUx: <UiUxIcon />,
  Devolpment: <DevelopmentIcon />,

  DataMarketing: <DataMarketIcon />,

  MultiUnit: <MultiUnitIcon />,

  Investment: <NNNInvestmentIcon />,

  Redevolpment: <RedovelopmentIcon />,

  Industrial: <IndustrialIcon />,
};

export const BlogLinkIcons: any = {
  video: <Video color={"var(--social-back)"} />,
  audio: <Audio color={"var(--social-back)"} />,
  website: <Web color={"var(--social-back)"} />,
  file: <File color={"var(--social-back)"} />,
};

export const LOGIN_SECRET = "HlyX5882";
