import { IonButton, IonCard, IonLabel } from "@ionic/react";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { RightArrowIcon } from "../assets/icons/ServiceTabIcons";
import { ImageType, ServiceCardProps, ServiceProps } from "../interface";
import FeaturedServices from "./FeaturedServices";
import ServiceDetails from "./ServiceDetails/ServiceDetails";
import "./ServicesTab.css";
import axios from "axios";
import { featuredServices } from "../data/userData";
import { baseUrl } from "../config";

export const ServiceCard = ({
  title,
  children,
  button,
  ...rest
}: ServiceCardProps) => {
  const { className } = rest;
  return (
    <IonCard className={`service-card-container ${className}`}>
      <div className="service-card-title">
        <IonLabel className="card-heading-lg">{title}</IonLabel>
        {button && (
          <IonButton fill="outline" shape="round" className="view-all-button">
            {button}
          </IonButton>
        )}
      </div>
      {children}
    </IonCard>
  );
};

const MainServiceCard = ({
  title,
  image,
  number,
  color,
  onClick, // Added onClick prop
}: {
  title: string;
  image?: ImageType;
  icon?: number;
  number?: number;
  color?: any;
  onClick?: () => void; // Type for onClick prop
}) => {
  return (
    <div
      className="dashboard-icon-section"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div style={{ backgroundColor: `${color}` }} className="dashboard-icon">
        <p className="number_services">{number}</p>
      </div>
      {image && (
        <div className="dashboard-image">
          <img src={image.url} alt={image.altText} height={55} width={57} />
        </div>
      )}

      <div className="dashboard-report-section">
        <div>
          <h6>{title}</h6>
          <p>View Service Details</p>
        </div>
        <RightArrowIcon />
      </div>
    </div>
  );
};

const ServicesTab = () => {
  const [servicesData, setServicesData] = useState<ServiceProps[]>([]);
  const [selectedService, setSelectedService] = useState<ServiceProps | null>(
    null
  ); // State for selected service
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for drawer visibility
  const params = useParams();
  const { cardId } = params;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${baseUrl}/card/find/services-page/${cardId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setServicesData(res.data.card.services);
        console.log("resofservices", res.data.card.services);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cardId]);

  const colorArray = ["#005DB2", "#3C9D00", "#6905B8", "#FF7A00"];

  const featured = servicesData?.filter((item) => item.type === "featured");
  const mainServices = servicesData?.filter((item) => item.type === "main");

  const openDrawer = (service: ServiceProps) => {
    setSelectedService(service);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <ServiceCard
        title="Business Services"
        button="View All"
        className="border_none"
      >
        {featured.length > 0 && (
          <>
            <h6 className="feature-services-title">Featured Services</h6>
            <div className="featured-services-cards">
              {featured?.map(
                (item, index) =>
                  item.type === "featured" && (
                    <FeaturedServices
                      lineColor={
                        featuredServices[index % featuredServices.length]
                          ?.lineColor
                      }
                      title={item.title}
                      description={item.details}
                      photo={item?.photo}
                      url={item?.url}
                    />
                  )
              )}
            </div>
          </>
        )}

        {mainServices.length > 0 && (
          <div>
            <h6 className="feature-services-title">Main Services</h6>
            <div className="cards-list">
              {mainServices.map(
                (item, index) =>
                  item.type === "main" && (
                    <MainServiceCard
                      title={item.title}
                      number={index + 1}
                      color={colorArray[index % colorArray.length]}
                      onClick={() => openDrawer(item)} // Open drawer with selected service
                    />
                  )
              )}
            </div>
          </div>
        )}
      </ServiceCard>

      {selectedService && (
        <ServiceDetails
          open={isDrawerOpen}
          onClose={closeDrawer}
          title={selectedService.title}
          description={selectedService.details}
          photo={selectedService.photo}
          url={selectedService.url}
        />
      )}
    </>
  );
};

export default ServicesTab;
