import { IonAccordionGroup, IonCard, IonLabel } from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CalendarIcon,
  CallIcon,
  ChevronIcon,
  FaxIcon,
  LocateFillIcon,
  MailIcon,
  MobileIcon,
  WorldIcon,
} from "../assets/icons";
import { SocialMediaIcons } from "../constants/index";
import { ContactCardProps } from "../interface";
import "../style/components/ContactTab.css";
import LinkCard from "./LinkCard";
import HeroSession from "./MediaCard/heroSession";
import { baseUrl } from "../config";

const ContactCard = ({ title, children, ...rest }: ContactCardProps) => {
  const { className } = rest;
  return (
    <IonCard className={`card-container ${className}`}>
      <IonLabel className="content-card-heading">{title}</IonLabel>
      {children}
    </IonCard>
  );
};

const ContactTab = ({
  openLinkWith,
  setIsButtonVisible,
  isButtonVisible,
  openShareContact,
  contactData,
}: any) => {
  const params = useParams();
  const { cardId } = params;

  const formatPhoneNumber = (number: any) => {
    // Remove any non-digit characters from the phone number
    const digits = number.replace(/\D/g, "");

    // Check if the remaining digits are 10 digits long
    if (digits.length === 10) {
      // Format the phone number as +1 (XXX) XXX-XXXX
      const formatted = `+1 (${digits.slice(0, 3)}) ${digits.slice(
        3,
        6
      )}-${digits.slice(6)}`;
      return formatted;
    } else {
      return "Invalid phone number format";
    }
  };

  const isLinkComplete = (value: string, targetValue: string): boolean => {
    return value.includes(targetValue);
  };

  return (
    <>
      <HeroSession
        contactData={contactData}
        openLinkWith={openLinkWith}
        setIsButtonVisible={setIsButtonVisible}
        isButtonVisible={isButtonVisible}
        openShareContact={openShareContact}
      />
      {contactData?.emails.length > 0 && (
        <ContactCard title="Email">
          {contactData?.emails.map((item: any) => {
            return (
              <a
                href={`mailto:${item?.email}`}
                target={"_blank"}
                rel="noreferrer"
                className="anchor-clear"
              >
                <div className="flex align-center justify-contain">
                  <div className="flex align-center">
                    <MailIcon
                      color={"var(--icon-back)"}
                      white={"var(--text-color)"}
                    />
                    <IonLabel className="icon_text">{item?.email}</IonLabel>
                  </div>
                  <ChevronIcon />
                </div>
              </a>
            );
          })}
        </ContactCard>
      )}

      {contactData?.phones.length > 0 && (
        <ContactCard title="Phone">
          <IonAccordionGroup expand="compact" className="phone-card-container">
            {contactData?.phones.map((item: any) => {
              return (
                <a
                  href={`tel:${formatPhoneNumber(item?.phone)}`}
                  className="anchor-clear"
                >
                  <div className="flex align-center justify-contain mb-11">
                    <div className="flex align-center">
                      {item?.type === "mobile" ? (
                        <MobileIcon
                          color={"var(--icon-back)"}
                          white={"var(--text-color)"}
                        />
                      ) : item?.type === "office" ? (
                        <CallIcon
                          color={"var(--icon-back)"}
                          white={"var(--text-color)"}
                        />
                      ) : (
                        <FaxIcon white={"var(--text-color)"} />
                      )}

                      <IonLabel className="icon_text">
                        {formatPhoneNumber(item?.phone)}
                      </IonLabel>
                    </div>

                    <ChevronIcon />
                  </div>
                </a>
              );
            })}
          </IonAccordionGroup>
        </ContactCard>
      )}
      {contactData?.calendars.length > 0 && (
        <ContactCard title="Calendar">
          {contactData?.calendars.map((item: any) => {
            return (
              <a
                href={
                  item?.url.includes("https://cal.com/")
                    ? item?.url
                    : `https://cal.com/${item?.url}`
                }
                className="anchor-clear"
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="flex align-center justify-contain mx-19">
                  <div className="flex align-center">
                    <CalendarIcon
                      color={"var(--icon-back)"}
                      white={"var(--text-color)"}
                    />
                    <IonLabel className="icon_text">{item?.title}</IonLabel>
                  </div>
                  <ChevronIcon />
                </div>
              </a>
            );
          })}
        </ContactCard>
      )}

      {contactData?.websites.length > 0 && (
        <ContactCard title="Website">
          {contactData?.websites.map((item: any) => {
            return (
              <a
                className="anchor-clear"
                target={"_blank"}
                rel="noreferrer"
                href={
                  item?.url.includes("https://")
                    ? item?.url
                    : `https://${item?.url}`
                }
              >
                <div className="flex align-center justify-contain mx-19">
                  <div className="flex align-center">
                    <WorldIcon
                      color={"var(--icon-back)"}
                      white={"var(--text-color)"}
                    />
                    <IonLabel className="icon_text">{item?.url}</IonLabel>
                  </div>

                  <ChevronIcon />
                </div>
              </a>
            );
          })}
        </ContactCard>
      )}

      {contactData?.officeAddresses.length > 0 && (
        <ContactCard title="Office">
          {contactData?.officeAddresses.map((item: any) => {
            return (
              <a
                href={`https://www.google.com/maps/place/${item?.streetAddress}`}
                className="anchor-clear"
                target={"_blank"}
                rel="noreferrer"
              >
                <div className="flex align-center justify-contain mx-19">
                  <div className="flex align-center">
                    <LocateFillIcon
                      color={"var(--icon-back)"}
                      white={"var(--text-color)"}
                    />
                    <IonLabel className="icon_text office_icon_text">
                      {item?.streetAddress?.trim()}, {item.city?.trim()},{" "}
                      {item.state} {item.zipcode}
                    </IonLabel>
                  </div>
                  <ChevronIcon />
                </div>
              </a>
            );
          })}
        </ContactCard>
      )}

      {contactData?.socialMedias.length > 0 && (
        <ContactCard title="Social Media">
          <div className="Social-icons-list">
            {contactData?.socialMedias?.map(
              ({ profileUrl, platform }: any, index: number) => (
                <a
                  href={profileUrl}
                  className="anchor-clear"
                  target={"_blank"}
                  rel="noreferrer"
                  key={index}
                >
                  <div className="icon-card">
                    <>
                      {SocialMediaIcons[platform]}
                      <IonLabel className="iconlabel">{platform}</IonLabel>
                    </>
                  </div>
                </a>
              )
            )}
          </div>
        </ContactCard>
      )}
      {contactData?.additionalLinks.length > 0 && (
        <ContactCard title="Links" className="border_none">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 25,
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            {contactData?.additionalLinks?.map(
              (
                { typeContent, url, title, description }: any,
                index: number
              ) => (
                <LinkCard
                  key={index}
                  icon={typeContent}
                  title={title}
                  hyperaddress={url}
                  description={
                    description ? description : "Description not available"
                  }
                />
              )
            )}
          </div>
        </ContactCard>
      )}
    </>
  );
};

export default ContactTab;
