import { FC } from 'react';
import { AboutProps } from '../../../../../interface';
import { educationProps } from '../../../../../data/userData';
import '../../../../../style/components/Education.css';
import moment from 'moment';
interface IEducationCard {
  aboutData: AboutProps;
}

const EducationCard: FC<IEducationCard> = ({ aboutData }) => {
  function processWords(input: string) {
    var s = input.split(' ');
    var charBuffer = '';
    s.forEach((element) => {
      if (element.length > 0) {
        charBuffer += element[0];
      }
    });
    return charBuffer;
  }
  return (
    <div className='education_list'>
      {aboutData.education.map((item: any, index) => {
        return (
          <div className='education_card_container' key={index}>
            <div
              className={`education_color_line color-${
                educationProps[index % educationProps.length]?.lineColor
              }`}
            />
            <div className='education_info'>
              <div className='education_details_container'>
                <div style={{ width: '70%' }} className='education_details'>
                  <h6>
                    {item?.schoolName.length > 20
                      ? item?.schoolName.slice(0, 19) + '...'
                      : item?.schoolName}
                  </h6>
                  <p>{`${
                    item.startDate
                      ? moment(item.startDate).format('YYYY')
                      : 'Not Provided'
                  }-${
                    item.endDate
                      ? moment(item.endDate).format('YYYY')
                      : 'Not Provided'
                  }`}</p>
                </div>
                {item.image ? (
                  <img src={item?.image?.url} alt={item.schoolName} />
                ) : (
                  <div
                    className={`image-replacer color-${
                      educationProps[index % educationProps.length]?.lineColor
                    }`}
                  >
                    <p>{processWords(item.schoolName)}</p>
                  </div>
                )}
              </div>

              <div className='education_info_text'>
                <h6>{item?.academicFocus}</h6>
                <p>{item?.degreeType}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EducationCard;
