import { IonContent, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import ProfileCard from "../../componentsDemo/ProfileCard";
import { baseUrl } from "../../config";
import "../../style/page/professional.css";
import axios from "axios";
import { ProfileContext } from "../../context/profileContext";

interface ICardData {
  activeCard: boolean;
  cardName: string;
  city: string;
  company: string;
  coverPhoto?: string;
  fullName: string;
  profTitle: string;
  publicCard: boolean;
  slug?: string;
  state?: string;
  templateId?: string;
  userAvatar: string;
  companyIcon: string;
  brandedCoverPhoto?: string;
}

const DemoMainPage = () => {
  // const { cardId } = useParams();
  const [cardData, setCardData] = useState<any>();
  // const history = useHistory();
  const { userProfile } = useContext(ProfileContext);
  console.log("1231231231231231", userProfile);

  // useEffect(() => {
  //   if (cardId) {
  //     axios
  //       .get(`${baseUrl}/card/find/base-info/${cardId}`)
  //       .then((res) => {
  //         console.log("resofCard", res);
  //         // localStorage.setItem('cardType', res.data.card.profileLayout);
  //         setCardData(res.data.card);
  //         if (!res?.data?.card?.brandedCoverPhoto) {
  //           history.push(`/link/${cardId}`);
  //         }
  //       })
  //       .catch((err) => {
  //         if (err?.response?.status === 404) {
  //           window.location.pathname = "/page-not-found";
  //           // history.push('/page-not-found');
  //         }
  //         console.log(err, "RRRRR");
  //       });
  //   }
  // }, []);

  return (
    <IonPage>
      <IonContent class="overflow-hidden">
        <ProfileCard
          blend={userProfile?.blend}
          id={userProfile?.id}
          companyName={
            userProfile?.jobTitle?.companyName
              ? userProfile?.jobTitle?.companyName
              : ""
          }
          backgroundImage={
            userProfile?.backgroundImage?.url
              ? userProfile?.backgroundImage?.url
              : ""
          }
          brandedCoverPhoto={
            cardData?.brandedCoverPhoto ? cardData?.brandedCoverPhoto : ""
          }
          name={userProfile?.name ? userProfile?.name : ""}
          position={
            userProfile?.jobTitle?.position
              ? userProfile?.jobTitle?.position
              : ""
          }
          location={`${userProfile?.address ? userProfile?.address : ""}, ${
            cardData?.state ? cardData?.state : ""
          }`}
          companyLogo={
            userProfile?.companyLogo?.url ? userProfile?.companyLogo?.url : ""
          }
          link={cardData?.cardName ? cardData?.cardName : ""}
        />
      </IonContent>
    </IonPage>
  );
};

export default DemoMainPage;
