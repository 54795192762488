import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
} from "@ionic/react";
import "../../style/components/MediaCard/mediaCard.css";
import { BreifIcon, LinkBtnIcon, LocateIcon } from "../../assets/icons";
import { useEffect, useRef } from "react";
import { ProfileContext } from "../../context/profileContext";
import axios from "axios";
import { baseUrl } from "../../config";
import { IContactData } from "../../interface";
import Share from "../../assets/icons/Share.svg";

const HeroSession = ({
  contactData,
  openLinkWith,
  setIsButtonVisible,
  isButtonVisible,
  openShareContact,
}: any) => {
  // const { userProfile } = useContext(ProfileContext);
  // const { image, jobTitle, name, address, companyLogo } = userProfile;
  // const[jobTitle]=userProfile.contact[]
  const targetRef = useRef(null);
  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0,
    };

    const interval = setInterval(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsButtonVisible(false);
          } else {
            setIsButtonVisible(true);
          }
        });
      }, options);

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }

      return () => {
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      };
    }, 500);
    return () => clearInterval(interval);
  }, [targetRef.current, isButtonVisible]);

  return (
    <>
      {contactData && (
        <>
          <div style={{ position: "relative" }}>
            {contactData?.coverPhoto && (
              <div className="cover_photo_container">
                <img
                  alt="profile avatar"
                  src={contactData?.coverPhoto}
                  className="cover_photo"
                />
              </div>
            )}
            {/* {contactData ? (
            <img
              alt='company logo'
              className="joi-avatar-icon"
              src={contactData?.companyIcon}
            />
          ) : ( */}
            {contactData?.coverPhoto && (
              <img
                style={{ bottom: contactData?.coverPhoto ? "-55px" : "unset" }}
                alt="company icon"
                className="avatar-icon"
                src={contactData?.companyIcon}
              />
            )}
            {/* )} */}
          </div>
          <IonCard
            ref={targetRef}
            style={{
              paddingBottom: "40px",
              paddingTop: contactData?.coverPhoto ? "" : "90px",
            }}
            className="cover_photo_card ion_Card"
          >
            <IonCardSubtitle>{contactData?.fullName}</IonCardSubtitle>

            <IonCardContent
              style={{ padding: "10px 0" }}
              className="ion-content"
            >
              <div className="ion_text">
                <BreifIcon color="var(--text-color)" />
                <p>
                  {contactData?.profTitle} at <b>{contactData?.company}</b>
                </p>
              </div>

              <div className="ion_text">
                <LocateIcon color="var(--text-color)" />
                <p>{`${contactData?.city ? contactData?.city : ""}, ${
                  contactData?.state ? contactData?.state : ""
                }`}</p>
              </div>
            </IonCardContent>
          </IonCard>

          <div
            className="br-btm"
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <IonButton
              expand="block"
              className="profile-footer-button"
              // routerLink={`link/${id}`}
              onClick={openLinkWith}
            >
              Add Link
            </IonButton>
            <IonButton
              onClick={openShareContact}
              color="#0000"
              expand="block"
              className="profile-footer-button white_profile"
              // routerLink={`/link/${id}`}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img src={Share} alt="" />
                Share
              </div>
            </IonButton>
          </div>
        </>
      )}
    </>
  );
};
export default HeroSession;
