import "../../../../style/components/AboutCard/aboutCard.css";
import { IonButton, IonCard, IonLabel } from "@ionic/react";
import { useParams } from "react-router-dom";
import ReadMoreText from "../../../ReadMoreText";
import BusinessGoals from "./BusinessGoals";
import UserDetailCard from "../../UserDetailCard/UserDetailCard";
import Accolades from "./AccoladesItem";
import Achievements from "./Achievements/Achievements";
import EducationCard from "./EducationCard";
import Icebreakers from "./Icebreakers/Icebreakers";
import BusinessNeeds from "./BusinessNeeds/BusinessNeeds";
import { useEffect, useState } from "react";
import { AboutTabProps, AboutProps } from "../../../../interface";
import axios from "axios";
import { baseUrl } from "../../../../config";

export const AboutCard = ({
  title,
  children,
  button,
  ...rest
}: AboutTabProps) => {
  const { className } = rest;
  return (
    <IonCard className={`card-container ${className}`}>
      <div className="about-card-title">
        <IonLabel className="card-heading-lg">{title}</IonLabel>
        {button && (
          <IonButton
            fill="outline"
            shape="round"
            color="var(--card-button-color)"
            className="view-all-button"
          >
            {button}
          </IonButton>
        )}
      </div>
      {children}
    </IonCard>
  );
};

const AboutTab = ({ contactData }: any) => {
  const [aboutData, setAboutData] = useState<AboutProps>({
    about: "",
    accolades: [],
    achievements: [],
    businessGoals: [],
    businessNeeds: [],
    city: "",
    company: "",
    education: [],
    fullName: "",
    hobbies: [],
    industry: "",
    interests: [],
    motto: "",
    occupation: "",
    profTitle: "",
    skills: [],
    state: "",
    userAvatar: "",
  });

  const params = useParams();
  const { cardId } = params;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (cardId) {
      axios
        .get(`${baseUrl}/card/find/about-page/${cardId}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          console.log("resofabout", res.data.card);
          setAboutData(res?.data?.card);
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            window.location.pathname = "/page-not-found";
          }
          console.log(err);
        });
    }
  }, []);
  return (
    <>
      {aboutData && (
        <UserDetailCard aboutData={aboutData} contactData={contactData} />
      )}
      {aboutData?.about && (
        <AboutCard title="Bio">
          <ReadMoreText text={aboutData?.about} limit={171} />
        </AboutCard>
      )}
      {aboutData?.company && (
        <AboutCard title="Profession" className="profession-about-card">
          <div className="profession-list-gap">
            <div className="profession-list">
              <div className="profession-item">
                <div className={`profession-line line-blue`} />
                <p className="profession-title">Company</p>
                <p className="profession-description">{aboutData?.company}</p>
              </div>
            </div>
            {aboutData?.profTitle && (
              <div className="profession-list">
                <div className="profession-item">
                  <div className={`profession-line line-green`} />
                  <p className="profession-title">Title / Role</p>
                  <p className="profession-description">
                    {aboutData?.profTitle}
                  </p>
                </div>
              </div>
            )}
            {aboutData?.occupation && (
              <div className="profession-list">
                <div className="profession-item">
                  <div className={`profession-line line-purple`} />
                  <p className="profession-title">Occupation / Profession</p>
                  <p className="profession-description">
                    {aboutData?.occupation}
                  </p>
                </div>
              </div>
            )}
            {aboutData?.industry && (
              <div className="profession-list">
                <div className="profession-item">
                  <div className={`profession-line line-orange`} />
                  <p className="profession-title">Industry</p>
                  <p className="profession-description">
                    {aboutData?.industry}
                  </p>
                </div>
              </div>
            )}
          </div>
        </AboutCard>
      )}
      {aboutData?.education.length > 0 && (
        <AboutCard title="Education" className="education-about-card">
          <EducationCard aboutData={aboutData} />
        </AboutCard>
      )}
      {aboutData?.accolades.length > 0 && (
        <AboutCard
          title="Accolades"
          button="View All"
          className="accolades-about-card"
        >
          <Accolades aboutData={aboutData} />
        </AboutCard>
      )}
      {aboutData?.achievements.length > 0 && (
        <AboutCard
          title="Achievements"
          button="View All"
          className="achievement-about-card"
        >
          <Achievements aboutData={aboutData} />
        </AboutCard>
      )}
      {aboutData?.businessNeeds.length > 0 && (
        <AboutCard title="Business Needs" className="business-needs-about-card">
          <BusinessNeeds aboutData={aboutData} />
        </AboutCard>
      )}
      {aboutData?.businessGoals.length > 0 && (
        <AboutCard
          title="Business Goals"
          className="bussiness-goals-about-card"
        >
          <BusinessGoals aboutData={aboutData} />
        </AboutCard>
      )}
      {aboutData?.hobbies.length > 0 ||
        aboutData?.skills.length > 0 ||
        aboutData?.interests.length > 0 ||
        (aboutData?.motto && (
          <AboutCard
            title="Icebreakers"
            className="border_none icebreakers-about-card"
          >
            <Icebreakers aboutData={aboutData} />
          </AboutCard>
        ))}
    </>
  );
};

export default AboutTab;
