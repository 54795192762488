import { IonButton, IonContent, IonPage } from "@ionic/react";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import ContactTab from "../../components/ContactTab";
import InfoButton from "../../components/InfoButton";
import Header from "../../components/Profile/Header";
import Portfolio from "../../components/Profile/Portfolio";
import AboutTab from "../../components/Profile/ProfileTabs/AboutTab";
import ServicesTab from "../../components/ServicesTab";
import Tabs from "../../components/Tabs";
import { ProfileContext } from "../../context/profileContext";
import "../../style/page/profile.css";
import PortfolioTab from "../../components/PortfolioTab";
import hlyxLogo from "../../assets/HlyxLogo.svg";
import axios from "axios";
import { baseUrl } from "../../config";
import PrivateProfile from "../../components/PrivateProfile/PrivateProfile";
import LinkWith from "../../components/LinkWith/LinkWith";
import ContactShare from "../../components/ContactShare/ContactShare";
export const ProfileTabs = ["Contact", "About", "Services", "Portfolio"];

const ProfileTabsContent = ({
  activeTab,
  openLinkWith,
  setIsButtonVisible,
  isButtonVisible,
  openShareContact,
  contactData,
}: {
  activeTab: string;
  openShareContact: any;
  openLinkWith: any;
  setIsButtonVisible: any;
  isButtonVisible: boolean;
  contactData: any;
}) => {
  const { userProfile } = useContext(ProfileContext);
  const ProfileTabs = [
    <ContactTab
      openLinkWith={openLinkWith}
      setIsButtonVisible={setIsButtonVisible}
      isButtonVisible={isButtonVisible}
      openShareContact={openShareContact}
      contactData={contactData}
    />,
    <AboutTab contactData={contactData} />,
    <ServicesTab />,
    <Portfolio />,
  ];
  return userProfile?.name === "Dr. Kenji Awurama" && activeTab === "3" ? (
    <PortfolioTab />
  ) : (
    ProfileTabs[+activeTab]
  );
};

const Profile = () => {
  const [activeTab, setActiveTab] = useState("0");
  const [card, setCard] = useState<any>("");
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [addLink, setAddLink] = useState<boolean>(false);
  const [shareContact, setShareContact] = useState<boolean>(false);
  const pageRef = useRef<any>(null);
  const [contactData, setContactData] = useState<any>();

  const closeLinkWith = () => {
    setAddLink(false);
  };
  const closeShareContact = () => {
    setShareContact(false);
  };
  const openShareContact = () => {
    scrollToElement();
    setShareContact(true);
  };

  const scrollToElement = () => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "instant" });
    }
  };
  const openLinkWith = () => {
    scrollToElement();
    setAddLink(true);
  };

  const params = useParams();
  const { cardId } = params;

  const handleClick = () => {
    window.location.href = "https://www.hlyx.io/register";
  };

  useEffect(() => {
    getCardInfo();
  }, []);

  const getCardInfo = () => {
    axios
      .get(`${baseUrl}/card/find/base-info/${cardId}`)
      .then((res) => {
        console.log("resofCard", res?.data?.card);
        setCard(res?.data?.card);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          window.location.pathname = "/page-not-found";
        }
        console.log(err);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (cardId) {
      axios
        .get(`${baseUrl}/card/find/contact-page/${cardId}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          console.log("resofCard", res.data.card);
          setContactData(res.data.card);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 100) {
  //       setIsVisible(true);
  //     } else {
  //       setIsVisible(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return (
    <>
      <IonPage>
        <IonContent
          className={`profile-container ${addLink ? "" : "overflow-auto"}  `}
        >
          {card && (
            <div ref={pageRef} className="sticky-header">
              {card?.publicCard ? (
                <>
                  <Header />
                  {card?.profileLayout === "minimal" ? (
                    <ProfileTabsContent
                      activeTab={activeTab}
                      openLinkWith={openLinkWith}
                      setIsButtonVisible={setIsButtonVisible}
                      isButtonVisible={isButtonVisible}
                      openShareContact={openShareContact}
                      contactData={contactData}
                    />
                  ) : (
                    card?.profileLayout === "premium" && (
                      <>
                        <Tabs
                          tabList={ProfileTabs}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                        />
                        <ProfileTabsContent
                          activeTab={activeTab}
                          openShareContact={openShareContact}
                          openLinkWith={openLinkWith}
                          setIsButtonVisible={setIsButtonVisible}
                          isButtonVisible={isButtonVisible}
                          contactData={contactData}
                        />
                      </>
                    )
                  )}
                  <div
                    style={{
                      paddingBlock: 10,
                      backgroundColor: "var(--bg-color)",
                    }}
                  >
                    <div className="line-btn" />
                    <IonButton
                      onClick={handleClick}
                      role="button"
                      size="large"
                      className="outline-btn"
                      fill="outline"
                      expand="block"
                      shape="round"
                      style={{
                        fontWeight: 600,
                        fontSize: 15,
                        lineHeight: "17px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Create Your Free Profile
                    </IonButton>
                    <div className="network_div">
                      <p>Your network empowered by</p>
                      <div>
                        <img src={hlyxLogo} alt="hlyxLogo" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Header optionsClass="hide_option" />
                  <PrivateProfile card={card} />
                </>
              )}
            </div>
          )}
        </IonContent>
        {card?.publicCard && (
          <InfoButton
            openLinkWith={openLinkWith}
            isButtonVisible={isButtonVisible}
          />
        )}
        {addLink && (
          <LinkWith closeLinkWith={closeLinkWith} name={card?.fullName} />
        )}
        {shareContact && <ContactShare closeShareContact={closeShareContact} />}
      </IonPage>
    </>
  );
};

export default Profile;
